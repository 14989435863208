<template>
  <div class="app-wrapper">
    <div class="app-wrapper-head">
      <div class="headCont">
        <div class="head-left">
          <img class="logo" src="@/assets/home/yklogo.png" alt="" />
          <div></div>
          <img src="@/assets/home/baozhang.png" alt="" />
        </div>
        <div class="head-right">
          <div class="tel">
            <img src="@/assets/home/tel1.png" alt="" />
            <a href="tel:tel:400-077-0148 "
              ><img src="@/assets/home/num.png" alt=""
            /></a>
          </div>
          <div class="free" @click="jump">
            免费法律咨询，快速解决您的法律问题
            <div class="one">
              一键咨询
              <img src="@/assets/home/zx.png" alt="" />
            </div>
            <img class="choose" src="@/assets/home/xuanze.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="main-container">
      <router-view :key="key" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutPage",
  data() {
    return {};
  },
  components: {},
  computed: {
    key() {
      return this.$route.path;
    },
  },
  methods: {
    // 咨询
    jump() {
      const timestamp = Date.now();
      window.window.open(
        "https://affimvip.baidu.com/cps5/chatIndex?siteToken=2cd56b20d7f98993c1ef05956f351f03&bid=4178264812020353000&eid=41390503&reqParam=%7B%22from%22%3A0,%22sid%22%3A%22-100%22,%22tid%22%3A%22-1%22,%22ttype%22%3A1,%22siteId%22%3A%2220353000%22,%22userId%22%3A%2241390503%22%7D&clickTime=" +
          timestamp,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  .app-wrapper-head {
    background-color: #fffcfa;
    box-shadow: 0px 4px 7px 0px rgba(248, 107, 50, 0.23);
    width: 100%;

    .headCont {
      width: 1200px;
      margin: 0 auto;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .head-left {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
          width: 190px;
          height: 36px;
        }

        div {
          width: 2px;
          height: 30px;
          background: #d0d0d0;
          margin: 20px;
        }
      }

      .head-right {
        display: flex;
        align-items: center;
        justify-content: center;

        .tel {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            margin-left: 10px;
          }
        }

        .free {
          width: 420px;
          height: 40px;
          background: #fcf9f5;
          border-radius: 50px;
          border: 4px solid #f5dbce;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          color: #18181c;
          line-height: 18px;
          font-style: italic;
          padding-left: 30px;
          position: relative;
          margin-left: 20px;

          .one {
            margin-right: -5px;
            width: 170px;
            height: 44px;
            background: linear-gradient(0deg, #fc9345, #f54f29, #f97e41);
            border-radius: 50px;
            color: #fff;
            font-size: 20px;
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              margin-left: 5px;
            }
          }

          .choose {
            position: absolute;
            top: -20px;
            right: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    .app-wrapper-head {
      .headCont {
        width: 100%;
        height: 1.07rem;
        justify-content: center;

        .head-left {
          .logo {
            width: 1.9rem;
            height: .36rem;
          }

          img:last-child {
            width: 0.93rem;
            height: 0.4rem;
          }
        }

        .head-right {
          .tel {
            img:first-child {
              width: 0.27rem;
              height: 0.27rem;
            }

            img:last-child {
              width: 1.89rem;
              height: 0.21rem;
            }
          }

          .free {
            display: none;
          }
        }
      }
    }
  }
}
</style>