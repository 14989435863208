import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入全局样式
import '@/styles/index.scss'

// 引入字体
import '@/font/font.css'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
